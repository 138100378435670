window.$ = window.jQuery = require('jquery'); // required for bootstrap
window.Popper = require('popper.js'); // required for tooltip, popup...

import 'bootstrap';
import 'slick-carousel';

import './modules';

var MYLANGUAGEKEY = 'languageId';
var MYPROVINCECOOKIEKEY = 'provinceId';
var STOREIDKEY = 'storeId';
var STORECODE = 'storeCode';
var STORENAMEKEY = 'storeName';
var STOREINFOKEY = 'storeInfo';
var PROVINCESELECTORKEY = 'provinceSelected';
var lang = getCookie(MYLANGUAGEKEY);

/*<![CDATA[*/
var defaultProvince = /*[[${provinceDefault}]]*/ 'qc';
var defaultLanguage = /*[[${languageDefault}]]*/ 'fr';
/*]]>*/


$('document').ready(function(){
    //SET DEFAULT LANG
    if (lang == null) {
        var navLang = navigator.language;
        lang = navLang.substring(0, 2);
    }

    // STYLEGUIDE

    if ($('body').hasClass('styleguide')) {
        console.log('You are in the styleguide')
    }

    //STORE SELECTION COOKIE
    if ($('#setStore').length > 0) {
        let storeId = $('#setStore').data('store-id');
        let storeCode = $('#setStore').data('store-code');
        let storeName = $('#setStore').data('store-name');
        let storeInfo = $('#setStore').data('store-info');

        let storeSelected = getCookie(STORECODE);

        if (storeCode == storeSelected) {
            $('#setStore').addClass('d-none');
        } else {
            $('#setStore').on('click', function () {
                setCookie(STOREIDKEY, storeId);
                setCookie(STORECODE, storeCode);
                setCookie(STORENAMEKEY, storeName);
                setCookie(STOREINFOKEY, storeInfo);
                $(this).addClass('d-none');
                showMyStore();
            });
        }
    }

    // CAREER FORM - CV
    var $resume = $('#resume');
    if ($resume.length > 0) {
        $resume.on('change', function () {
            //get the file name
            var fileName = $(this).val();
            //replace the "Choose a file" label
            $(this).next('.custom-file-label').html(fileName);
        });
    }

    //FLIPP FEATURED PRODUCTS
    var $featuredProducts = $('#featured-products');
    if ($featuredProducts.length > 0){
        var lang = getCookie(MYLANGUAGEKEY);
        var defaultStoreCode = (lang =='fr') ? '103':'106';
        var myStoreCode = getCookie('storeCode');
        var flyerStoreCode = (myStoreCode != null) ? myStoreCode : defaultStoreCode;

        //Appel pour les produits en vedette
        $.ajax({
            type: 'GET',
            url: 'https://api.flipp.com/flyerkit/v4.0/publications/marcheadonis/products',
            data: {
                access_token: '5d44987cf5cc1f3a6f2034fc73e7157c',
                locale: 'fr-CA',
                store_code: flyerStoreCode,
                page: 1
            },
            dataType: 'json',
            success: function (data) {
                var currentDay = Date.now();
                var productsCode = [];

                $.each(data, function(index, element) {
                    var validFrom = new Date(element.valid_from);

                    //Afficher que les produits vedettes sont bien actifs et cacher les rabais à venir
                    if(currentDay > validFrom.getTime() && productsCode.indexOf(element.id) < 0) {
                        productsCode.push(element.id);

                        var productTemplate = $('#product-template').html();
                        var productTemplateSr = $('#product-template-sr').html();

                        productTemplate = productTemplate.replace(new RegExp('__DESKTOP_IMG__', 'g'), element.medium_image_url);
                        productTemplate = productTemplate.replace(new RegExp('__ALT__', 'g'), element.name);
                        productTemplate = productTemplate.replace(new RegExp('__TITLE__', 'g'), element.name);
                        productTemplateSr = productTemplateSr.replace(new RegExp('__TITLE__', 'g'), element.name);
                        try{
                            productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), element.pre_price_text + parseFloat(Math.round(element.price_text * 100) / 100).toFixed(2));
                            productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), element.pre_price_text + parseFloat(Math.round(element.price_text * 100) / 100).toFixed(2));
                        }catch(err){
                            productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), element.pre_price_text + element.price_text);
                            productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), element.pre_price_text + element.price_text);
                        }
                        productTemplate = productTemplate.replace(new RegExp('__UNIT__', 'g'), element.post_price_text);
                        productTemplateSr = productTemplateSr.replace(new RegExp('__UNIT__', 'g'), element.post_price_text);

                        $('.featured-products__list').append(productTemplate);
                        $('#featured-products-sr').append(productTemplateSr);
                    }
                });
            },
            error: function (){
                $(".featured-products__wrapper").hide();
            },
            complete: function() {
                //Création du carrousel des produits vedettes
                $('#featured-products').slick({
                    infinite: true,
                    speed: 1000,
                    dots: true,
                    arrows: true,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    autoplay: false,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                dots: true,
                                arrows: true,
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                autoplay: false
                            }
                        },
                        {
                            breakpoint: 575,
                            settings: {
                                dots: false,
                                arrows: false,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                autoplay: true,
                                autoplaySpeed: 3000
                            }
                        }
                    ]
                });

                var $status = $('.paging-info');
                var $slickElement = $('#featured-products');

                $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
                    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $status.text((i) + '/' + (slick.slideCount));
                });
            }
        });
    }

    //TOOLTIPS
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    //LANGUAGE SWITCHER
    $(function () {
        $('[data-lang]').each(function() {
            $(this).on('click', function(e) {
                setCookie(MYLANGUAGEKEY, $(this).data('lang'));
            });
        })
    });

    //FOCUS ON FIRST FIELD OF A FORM IF ERRORS
    $('form').find('.error').first().parent().find('.form-control').focus();

    //FILTERS FOR RECIPIES
    $('label').on('click', function (e) {
        var selectedOption = $(this).find('input').attr('name');
        $('#recipes .recipe').each(function () {
            if (selectedOption === 'all' || $(this).hasClass(selectedOption)) {
                $(this).removeClass('d-none').addClass('d-flex');
            } else {
                $(this).removeClass('d-flex').addClass('d-none');
            }
        });

    });

    // CHECK IF PROVINCE HAS BEEN SELECTED
    var provinceSelected = getCookie(PROVINCESELECTORKEY);
    var myProvince = getCookie(MYPROVINCECOOKIEKEY);
    var myLanguage = getCookie(MYLANGUAGEKEY);
    if(!myLanguage){
        myLanguage = defaultLanguage;
    }

    if (provinceSelected == null) {
        // If no province has been selected, show modal
        $('#provinceSelection').modal({backdrop: 'static', keyboard: false}, 'show');

        $('#provinceSelection').on('click', 'button', function () {
            var myProvince = $(this).data('value');
            var myStoreId = $(this).data('storeId');
            var myStoreCode = $(this).data('storeCode');
            var myStoreName = $(this).data('storeName');
            var myStoreInfo = $(this).data('storeInfo');



            setCookie(STOREIDKEY, myStoreId);
            setCookie(STORECODE, myStoreCode);
            setCookie(STORENAMEKEY, myStoreName);
            setCookie(STOREINFOKEY, myStoreInfo);
            setCookie(PROVINCESELECTORKEY, true);

            $('#provinceSelection').modal('hide');

            showMyStore();

            window.location = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + (defaultProvince != myProvince ? '/' + myLanguage + '/' + myProvince : defaultLanguage != myLanguage ? '/' + myLanguage : '/');
        });
    } else {
        showMyStore();
    }

    //MAILCHIMP SUBMISSION AJAX
    var $form = $('#mc-embedded-subscribe-form');

    if ($form.length > 0) {
        $('#mc-embedded-subscribe-form').on('submit', function (e) {
            if (e) e.preventDefault();
            if(formIsValid($(this))){
                register($form);
            }
        });
    }

    // FLIPP
    // Flipp Hosted Circular
    if ($('#flipp-container').length) {
        var lang = getCookie('languageId');
        var defaultStoreCode = (lang =='fr') ? '103':'106';
        var myStoreCode = getCookie('storeCode');
        var flyerStoreCode = (myStoreCode != null) ? myStoreCode : defaultStoreCode;
        var containerId = 'flipp-container';
        var langFlyer = 'fr';

        //Validation de la langue disponible de la circulaire
        $.ajax({
            type: 'GET',
            async: false,
            url: 'https://api.flipp.com/flyerkit/v4.0/publications/marcheadonis',
            data: {
                access_token: '5d44987cf5cc1f3a6f2034fc73e7157c',
                locale: 'fr-CA',
                store_code: flyerStoreCode,
                page: 1
            },
            dataType: 'json',
            success: function (data) {
                langFlyer = data[0].locale;
            }
        });

        // Flipp Hosted Circular
        var containerId = 'flipp-container';
        var circFrame = new wishabi.hostedservices.iframe.decorate(
            containerId,
            'marcheadonis',
            wishabi.hostedservices.iframe.Sizing.PAGE,
            {
                minHeight: 600,
                initialHeight: 600,
                extraPadding: 0,
                queryParameters: 'store_code='+ flyerStoreCode + '&locale='+ langFlyer
            }
        );

        // wishabi.js configuration
        var domain = 'https://www.flyertown.ca';
        var theContainer = document.getElementById(containerId);
        var theFrame = theContainer.getElementsByTagName('iframe')[0];
        // Analytics API
        var analytics = new wishabi.hostedservices.iframe.Analytics(window,
            theFrame.contentWindow, {
                handleAnalyticsEvent: function(analytics, detail) {
                    if (detail.event_type == 'store_select'){
                        if (detail.store_id != undefined){
                            var cookieValue = detail.store_id;

                            var storeInfo = $('#'+cookieValue);
                            if(storeInfo && storeInfo.length > 0){
                                $('#selectedStoreInfo').html('<strong>' + storeInfo.data('name') + '</strong> : ' + storeInfo.data('address') + ' ' + storeInfo.data('phone'));
                                setCookie('storeName', storeInfo.data('name'));
                                setCookie('storeInfo', storeInfo.data('address') + ' ' + storeInfo.data('phone'));
                            }

                            setCookie('storeCode', cookieValue);
                        }
                    }
                }
            }, domain);
    }
});

function setCookie(cookieKey, cookieValue) {
    var d = new Date();
    d.setTime(d.getTime() + 10*365*24*60*60*1000); //10 years
    document.cookie = cookieKey + '=' + cookieValue + ';path=/ ;expires='+d.toGMTString()+';'
}

function getCookie(cookieKey) {
    var cookieKey = cookieKey + '=';
    var ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(cookieKey) == 0) return c.substring(cookieKey.length, c.length);
    }
    return null;
}

function showMyStore() {
    var myProvince = getCookie('myProvince');
    var myStoreId = getCookie('storeId');
    var myStoreCode = getCookie('storeCode');
    var myStoreName = getCookie('storeName');
    var myStoreInfo = getCookie('storeInfo');

    if (myStoreName == null || myStoreName == 'undefined') {
        //SET DEFAULT QUEBEC STORE
        var defaultStore = $('#default-store');

        myProvince = defaultStore.data('value');
        myStoreId = defaultStore.data('storeId');
        myStoreCode = defaultStore.data('storeCode');
        myStoreName = defaultStore.data('storeName');
        myStoreInfo = defaultStore.data('storeInfo');
        setCookie(STOREIDKEY, myStoreId);
        setCookie(STORECODE, myStoreCode);
        setCookie(STORENAMEKEY, myStoreName);
        setCookie(STOREINFOKEY, myStoreInfo);
    }

    var myStore = '<strong>' + myStoreName + '</strong> : ' + myStoreInfo;

    //INJECT STORE INFORMATION ON HEADER
    $('#selectedStoreInfo').html(myStore);
}

function register($form) {
    var languageSelection = getCookie(MYLANGUAGEKEY);

    $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serialize(),
        cache: false,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",

        error: function (err) {
            let errorMessage = 'Something went wrong. Please try later!';

            if (languageSelection == 'fr') {
                errorMessage = 'Nous éprouvons des problèmes. Veuillez réessayer plus tard !'
            }

            $('#mc-msg').append(errorMessage).addClass('alert alert-danger');
        },

        success: function (data) {
            if (data.result != "success") {
                //Show warning message
                let msg = data.msg;
                let msgTranslated = false;
                let mcMessages = {
                    'We have sent you a confirmation email': 'Nous vous avons envoyé un courriel de confirmation',
                    'Please enter a value': 'S\'il vous plaît, entrez une valeur',
                    'An email address must contain a single @': 'Une adresse courriel doit contenir un seul @',
                    'The domain portion of the email address is invalid (the portion after the @: )': 'La partie domaine de l\'adresse courriel n\'est pas valide (la partie après le @ :)',
                    'The username portion of the email address is empty': 'La partie nom d\'utilisateur de l\'adresse courriel n\'est pas valide (la partie avant le @ :)',
                    'This email address looks fake or invalid. Please enter a real email address': 'Ce courriel semble faux ou non valide. S\'il vous plaît, entrez un courriel valide'
                };

                //add target blank if message contain a link
                msg = msg.replace('<a href', ' <a target="_blank" href');

                //highlight field with error
                let errorField = msg.substring(0, msg.indexOf(' - '));
                $('input.required:eq(' + errorField + ')').focus();

                if (languageSelection == 'fr') {
                    for (let key in mcMessages) {
                        if (msg.indexOf(key) >= 0) {
                            msg = mcMessages[key];
                            msgTranslated = true;
                        }
                    }

                    if (msgTranslated) {
                        msg = msg.replace('is already subscribed to list', 'est déjà inscrit dans la liste');
                        msg = msg.replace('Click here to update your profile', 'Cliquez ici pour mettre vos informations à jour');
                    }
                } else {
                    msg = msg.substring(msg.indexOf(' - ') + 3);
                }

                $('#mc-msg').html(msg).addClass('alert alert-warning');
            }

            else {
                //Show success message and hide modal after.

                if (languageSelection == 'en') {
                    data.msg = 'Thank you for your subscription!';
                } else {
                    data.msg = 'Merci pour votre inscription !';
                }

                $('#mc-msg').text(data.msg).removeClass().addClass('alert alert-success').next().detach();

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'gaEvent',
                    'eventCategory': 'call to action',
                    'eventAction': 'subscribe',
                    'eventLabel': 'newsletter'
                });

                setTimeout(function () {
                    $('#newsletterForm').modal('hide');
                }, 2000);

            }
        }
    });
}

function formIsValid(form) {
    let validation = true;
    form.find('.required').each(function(){
        if (($(this).val()).length <= 0) {
            validation = false;
        }
    });

    return validation;
}